import { Outlet, Link } from "react-router-dom";

import styles from "./Layout.module.css";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Synergi Life Assistant</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}></ul>
                    </nav>
                    <h4 className={styles.headerRightText}>
                        <img src="DNV_logo.svg" alt="DNV" width="98" height={"42"} />
                    </h4>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
