export const formatJSONReadable = (json: JSON): string => {
    const formattedLines: string[] = ["\n"];

    for (const [key, value] of Object.entries(json)) {
        formattedLines.push(`${formatKey(key)}: ${typeof value === "object" ? JSON.stringify(value) : formatDate(value)}`);
    }

    return formattedLines.join("\n");
};

const formatKey = (key: string): string =>
    key
        .split("_")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

const formatDate = (value: string): string => {
    const date = new Date(value);
    return isNaN(date.getTime()) ? value : `${date.toLocaleDateString()} at ${date.toLocaleTimeString()}`;
};
