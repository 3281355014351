import { useRef, useState, useEffect } from "react";
import { SparkleFilled } from "@fluentui/react-icons";
import readNDJSONStream from "ndjson-readablestream";

import styles from "./Chat.module.css";

import {
    RetrievalMode,
    ChatAppResponse,
    ChatAppResponseOrError,
    ChatAppRequest,
    ResponseMessage,
    VectorFieldOptions,
    GPT4VInput,
    postChatApi
} from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { useAuth } from "react-oidc-context";
import { formatJSONReadable } from "./helpers";

const turnOffAuth = import.meta.env.VITE_TURN_OFF_AUTH;

const Chat = () => {
    const auth = useAuth();

    useEffect(() => {
        if (turnOffAuth !== "true" && !auth?.isAuthenticated && !auth?.isLoading) {
            !auth.error && auth.signinRedirect();
        }
    }, [auth, auth?.isAuthenticated, auth?.isLoading, auth?.error]);

    const shouldStream = true;

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const [draft, setDraft] = useState<object | undefined>(undefined);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);
    const [streamedAnswers, setStreamedAnswers] = useState<[user: string, response: ChatAppResponse][]>([]);

    const handleAsyncRequest = async (question: string, answers: [string, ChatAppResponse][], responseBody: ReadableStream<unknown>) => {
        let answer: string = "";
        let askResponse: ChatAppResponse = {};

        const updateState = (newContent: string) => {
            if (newContent === undefined) {
                return Promise.resolve();
            }
            return new Promise(resolve => {
                setTimeout(() => {
                    answer += newContent;
                    const latestResponse: ChatAppResponse = {
                        ...askResponse,
                        ...(askResponse?.choices?.length &&
                            askResponse?.choices?.length > 0 && {
                                choices: [{ ...askResponse?.choices?.[0], message: { content: answer, role: askResponse.choices?.[0]?.messages?.[1]?.role } }]
                            }),
                        ...(!askResponse?.choices?.length &&
                            askResponse?.delta?.content && {
                                choices: [{ ...askResponse?.choices?.[0], message: { content: answer, role: askResponse.choices?.[0]?.messages?.[1]?.role } }]
                            })
                    };
                    setIsStreaming(true);
                    setStreamedAnswers([...answers, [question, latestResponse]]);
                    resolve(null);
                }, 33);
            });
        };
        try {
            for await (let event of readNDJSONStream(responseBody)) {
                if (event.caseCreationStatus) {
                    const message =
                        event.caseCreationStatus === "created"
                            ? `Case created successfully. Case id: ${event.caseId}`
                            : "Failed to create a case. Please try again";
                    event = { ...event, choices: [{ message: { role: "assistant", content: message, caseId: event.caseId } }] };

                    askResponse = event;
                    setIsStreaming(false);
                    setIsLoading(false);
                    setDraft(undefined);
                    await updateState(message);
                } else if (event["case"]) {
                    const message = "This is a case that I can register in Synergi Life for you:" + formatJSONReadable(event["case"]) + "\n\nIs it correct?";
                    if (event?.["choices"]?.[0]?.["message"]) {
                        event["choices"][0]["message"] = message;
                    } else {
                        event = { ...event, choices: [{ message: { role: "assistant", content: message } }] };
                    }
                    askResponse = event;
                    setIsStreaming(false);
                    setIsLoading(false);
                    setDraft(event["case"]);
                    await updateState(message);
                } else if (event["choices"] && event["choices"][0]["context"] && event["choices"][0]["context"]["data_points"]) {
                    event["choices"][0]["message"] = event["choices"][0]?.messages?.[0] ?? event["choices"][0]["message"];
                    askResponse = event;
                    setIsStreaming(true);
                } else if (event["choices"] && event["choices"][0]?.messages?.[1]?.["content"]) {
                    setIsLoading(false);
                    askResponse = event;
                    await updateState(event["choices"][0]?.messages?.[1]?.content);
                } else if (event["choices"] && event["choices"][0]?.message?.["content"]) {
                    setIsLoading(false);
                    askResponse = event;
                    await updateState(event["choices"][0]?.message?.content);
                } else if (event["choices"] && event["choices"][0]["context"]) {
                    if (askResponse?.choices?.[0]?.context) {
                        askResponse.choices[0].context = { ...askResponse.choices[0].context, ...event["choices"][0]["context"] };
                    }
                    setIsStreaming(true);
                } else if (event.delta) {
                    askResponse = event;
                    await updateState(event.delta.content);
                } else if (event["error"]) {
                    throw Error(event["error"]);
                } else {
                    setIsStreaming(true);
                }
            }
        } catch (err) {
            console.log("Error ", err);
        } finally {
            setIsStreaming(false);
        }
        const fullResponse: ChatAppResponse = {
            ...askResponse,
            choices: [
                {
                    ...askResponse?.choices?.[0],
                    message: {
                        content: answer,
                        role: askResponse?.choices?.[0]?.messages?.[1]?.role,
                        parameters: { caseId: askResponse?.choices?.[0]?.messages?.[1]?.caseId }
                    }
                }
            ]
        };
        return fullResponse;
    };

    const makeApiRequest = async (question: string, draft?: object) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);

        try {
            const messages: ResponseMessage[] = answers.flatMap(a => [
                { content: a[0], role: "user" },
                { content: a?.[1]?.choices?.[0]?.message?.content, role: "assistant" }
            ]);

            const request: ChatAppRequest = {
                messages: [...messages, { content: question, role: "user" }],
                stream: shouldStream,
                context: {
                    overrides: {
                        prompt_template: undefined,
                        exclude_category: undefined,
                        top: 3,
                        retrieval_mode: RetrievalMode.Hybrid,
                        semantic_ranker: true,
                        semantic_captions: false,
                        suggest_followup_questions: false,
                        use_oid_security_filter: false,
                        use_groups_security_filter: false,
                        vector_fields: [VectorFieldOptions.Embedding],
                        use_gpt4v: false,
                        gpt4v_input: GPT4VInput.Texts
                    }
                },
                session_state: answers.length ? answers?.[answers?.length - 1]?.[1]?.choices?.[0].session_state : null,
                draft
            };

            const response = await postChatApi(request);
            if (!response.body) {
                throw Error("No response body");
            }
            if (shouldStream) {
                const parsedResponse: ChatAppResponse = await handleAsyncRequest(question, answers, response.body);
                setAnswers([...answers, [question, parsedResponse]]);
            } else {
                const parsedResponse: ChatAppResponseOrError = await response.json();
                if (response.status > 299 || !response.ok) {
                    throw Error(parsedResponse.error || "Unknown error");
                }
                setAnswers([...answers, [question, parsedResponse as ChatAppResponse]]);
            }
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setAnswers([]);
        setStreamedAnswers([]);
        setIsLoading(false);
        setIsStreaming(false);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "auto" }), [streamedAnswers]);

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        if (!(activeCitation === citation && selectedAnswer === index)) {
            setActiveCitation(citation);
        }

        setSelectedAnswer(index);
    };

    const onToggleTab = (index: number) => {
        setSelectedAnswer(index);
    };

    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <SparkleFilled fontSize={"120px"} primaryFill={"#91FFB4"} aria-hidden="true" aria-label="Chat logo" />
                            <h1 className={styles.chatEmptyStateTitle}>Chat with Synergi Life Assistant</h1>
                            <h2 className={styles.chatEmptyStateSubtitle}>Ask anything or try an example</h2>
                            <ExampleList onExampleClicked={onExampleClicked} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {isStreaming &&
                                streamedAnswers.map((streamedAnswer, index) => {
                                    return (
                                        <div key={index}>
                                            <UserChatMessage message={streamedAnswer[0]} />
                                            <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    isStreaming={true}
                                                    key={index}
                                                    answer={streamedAnswer[1]}
                                                    isSelected={false}
                                                    onCitationClicked={c => onShowCitation(c, index)}
                                                    onThoughtProcessClicked={() => onToggleTab(index)}
                                                    onSupportingContentClicked={() => onToggleTab(index)}
                                                    onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                    showFollowupQuestions={false}
                                                />
                                            </div>
                                        </div>
                                    );
                                })}
                            {!isStreaming &&
                                answers.map((answer, index) => (
                                    <div key={index}>
                                        <UserChatMessage message={answer[0]} />
                                        <div className={styles.chatMessageGpt}>
                                            <Answer
                                                isStreaming={false}
                                                key={index}
                                                answer={answer[1]}
                                                isSelected={false}
                                                onCitationClicked={c => onShowCitation(c, index)}
                                                onThoughtProcessClicked={() => onToggleTab(index)}
                                                onSupportingContentClicked={() => onToggleTab(index)}
                                                onFollowupQuestionClicked={q => makeApiRequest(q)}
                                                showFollowupQuestions={false}
                                            />
                                        </div>
                                    </div>
                                ))}
                            {isLoading && !isStreaming && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder="Type a new question (e.g. what are default values?)"
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question, draft)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Chat;
