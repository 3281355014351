import { ChatHelp24Regular, Bug24Regular } from "@fluentui/react-icons";
import { Button } from "@fluentui/react-components";

import styles from "./SettingsButton.module.css";

interface Props {
    className?: string;
}

export const SettingsButton = ({ className }: Props) => {
    const handleAskForHelpClick = () => {
        const email = "jedrzej.maczan@dnv.com";
        const subject = encodeURIComponent("Synergi Life Assistant Support Request");
        const body = encodeURIComponent("Hello, \n\n");
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

        window.location.href = mailtoLink;
    };

    const handleBugReportClick = () => {
        const email = "jedrzej.maczan@dnv.com";
        const subject = encodeURIComponent("Synergi Life Assistant Bug Report");
        const body = encodeURIComponent("Hello, \n\n");
        const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

        window.location.href = mailtoLink;
    };

    return (
        <>
            <div className={`${styles.container} ${className ?? ""}`}>
                <Button icon={<ChatHelp24Regular />} onClick={handleAskForHelpClick}>
                    {"Ask for help"}
                </Button>
            </div>
            <div className={`${styles.container} ${className ?? ""}`}>
                <Button icon={<Bug24Regular />} onClick={handleBugReportClick}>
                    {"Report bug"}
                </Button>
            </div>
        </>
    );
};
