import React from "react";
import ReactDOM from "react-dom/client";
import { createHashRouter, RouterProvider } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { AuthProvider, type AuthProviderProps } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { oidcClientId, oidcDefaultProviderId, oidcPostLogoutRedirectUri, oidcRedirectUri, oidcScope, oidcServiceUrl } from "./infrastructure/config";

const oidcConfig: AuthProviderProps = {
    authority: oidcServiceUrl,
    client_id: oidcClientId,
    redirect_uri: oidcRedirectUri,
    post_logout_redirect_uri: oidcPostLogoutRedirectUri,
    scope: oidcScope,
    acr_values: oidcDefaultProviderId?.length > 0 ? `idp:${oidcDefaultProviderId}` : undefined,
    response_type: "code",
    response_mode: "query",
    monitorSession: false,
    automaticSilentRenew: false,
    filterProtocolClaims: true,
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    onSigninCallback: (): void => {
        window.history.replaceState({}, document.title, window.location.pathname);
    }
};

initializeIcons();

const router = createHashRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Chat />
            },
            {
                path: "*",
                lazy: () => import("./pages/NoPage")
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <AuthProvider {...oidcConfig}>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </AuthProvider>
);
