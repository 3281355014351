const BACKEND_URI = import.meta.env.VITE_CHAT_URL;

import { ChatAppRequest } from "./models";

const getHeaders = (): Record<string, string> => ({
    "Content-Type": "application/json"
});

export async function postChatApi(request: ChatAppRequest): Promise<Response> {
    return await fetch(`${BACKEND_URI}/chat`, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(request)
    });
}

export const getCitationFilePath = (citation: string): string => `${BACKEND_URI}/content/${citation}`;
